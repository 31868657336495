import type { BaseRouter } from 'next/dist/shared/lib/router/router';
import Router from 'next/router';
import { setCookie } from 'nookies';
import queryParser from 'query-string';

import { getCookie } from 'Utils/cookieUtils';
import { read } from 'Utils/localStorageUtils';

import {
	BOOKING_FLOW_STAGE,
	CANONICAL_QUERY_WHITELIST,
	COOKIE,
	FILTER_AND_SORT_KEYS,
	FILTER_SORT_AND_OFFSET_KEYS,
	FLEXIBLE_START_TIME,
	NEXT_NEW_PRODUCTS_PAGINATION,
	QUERY_PARAM,
	REWRITE_NORMALISED_HREF_MAP,
	SORT_FILTER_KEYS,
	UserProfileTypes,
} from 'Constants/constants';

import { hasValidPax } from './bookingFlowUtils';
import { formatToInventoryTime } from './dateUtils';
import { isServer } from './envUtils';
import { fromEntries, getDecodedUrlSlugs } from './gen';
import { getUserProfileType } from './stateUtils';
import { replaceNotAlphaNumbericWithHyphen } from './stringUtils';
import { validateDate, validateTime } from './validationUtils';

export const getWebPathString = (str?: string) =>
	str && replaceNotAlphaNumbericWithHyphen(str.toLowerCase());

export const getSEOLanguageLabel = (lang: string) => {
	const langInLowerCase = lang.toLowerCase();
	if (/zh[-_]hans/.test(langInLowerCase)) return 'zh-Hans';
	if (/zh[-_]hant/.test(langInLowerCase)) return 'zh-Hant';

	return langInLowerCase;
};

export const getApiString = (str: string) =>
	(str || '').toUpperCase().replace(/-/g, '_');

/**
 * Returns internal host for server-side calls, else the public endpoint
 * Uses environment variables API_HOST_INTERNAL,
 *
 * @returns string - The api server location
 */
export const getApiBaseUrl = () => {
	if (isServer()) {
		return process.env.NEXT_PUBLIC_API_BASE_URL_INTERNAL;
	}

	return ''; // i.e use Relative URL, cookies will be attached correctly in this case for WL.
};

export const getApiCDNBaseUrl = ({ state = {} }) => {
	if (getUserProfileType(state) === UserProfileTypes.DISTRIBUTION_PARTNER) {
		return getApiBaseUrl();
	}

	return process.env.NEXT_PUBLIC_API_CDN_BASE_URL;
};

export const getApiCDNBaseUrlV2 = ({ state = {} }) => {
	if (getUserProfileType(state) === UserProfileTypes.DISTRIBUTION_PARTNER) {
		return getApiBaseUrl();
	}

	return process.env.NEXT_PUBLIC_API_CDN_BASE_URL_DEIMOS;
};

/**
 *
 * @returns string - The base URL of the server
 */
export const getBaseUrl = () => process.env.NEXT_PUBLIC_BASE_URL;

export const getBaseHost = () => process.env.NEXT_PUBLIC_BASE_HOST;

export const getBaseUrlWithLang = (lang?: string) => {
	return `${getBaseUrl()}${lang?.toLowerCase() !== 'en' ? `/${lang}` : ``}`;
};

export const getCategoryParams = (category: any) => {
	const { id, tags } = category;
	const tagsJoined = tags.join(',');
	return `tags=${tagsJoined}&categoryId=${id}`;
};

export const getQueryObject = (location: any) =>
	queryParser.parse(location.search);

export const getQueryObjectFromUrl = (url: string) => {
	if (!url || !url.includes('?')) return {};
	const queryString = url.slice(url.indexOf('?') + 1);
	return queryParser.parse(queryString) || {};
};

export const getStringifiedQueryFromObject = (queryJson: any, options?: any) =>
	queryParser.stringify(queryJson, options);

export const addQueryParamToString = (
	query: string,
	param: Record<string, string>,
) => {
	let parsed = queryParser.parse(query);
	parsed = { ...parsed, ...param };
	return queryParser.stringify(parsed);
};

export const getPathnameFromUrl = (url?: string) => {
	if (!url) return '';
	if (url.includes('?')) return url.slice(0, url.indexOf('?'));
	return url;
};

export const getBookingLandingStageUrl = (url: any) => {
	const bookingUrl = url.replace('/tour/', '/book/');
	const { SELECT } = BOOKING_FLOW_STAGE;
	const pathname = getPathnameFromUrl(bookingUrl);
	const query = getQueryObjectFromUrl(bookingUrl);
	const selectPageQuery = Object.assign({}, query, { stage: SELECT });

	return `${pathname}?${getStringifiedQueryFromObject(selectPageQuery)}`;
};

export const isQueryValid = (query: Record<string, any>, queryParam: string) =>
	query && query[queryParam] && !Array.isArray(query[queryParam]);

export const getCurrencyFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.CURRENCY_CODE)
		? (query[QUERY_PARAM.CURRENCY_CODE] as any).toUpperCase()
		: null;
};

export const getLanguageFromUrl = (location: any) => {
	const query = getQueryObject(location);

	return isQueryValid(query, QUERY_PARAM.LANGUAGE_CODE)
		? (query[QUERY_PARAM.LANGUAGE_CODE] as any).toLowerCase()
		: null;
};

export const getDateFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.DATE) &&
		validateDate(query[QUERY_PARAM.DATE])
		? query[QUERY_PARAM.DATE]
		: null;
};

export const getTimeFromUrl = (location: any) => {
	const query = getQueryObject(location);
	if (isQueryValid(query, QUERY_PARAM.TIME)) {
		const time = query[QUERY_PARAM.TIME];
		if (time === FLEXIBLE_START_TIME) {
			return time;
		} else if (validateTime(time) && typeof time === 'string') {
			return formatToInventoryTime(time);
		}
	}
	return null;
};

export const getTourFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.TOUR_ID)
		? query[QUERY_PARAM.TOUR_ID]
		: null;
};

export const getVariantFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.VARIANT_ID)
		? query[QUERY_PARAM.VARIANT_ID]
		: null;
};

export const getPaxFromUrl = (location: any) => {
	const query = getQueryObject(location);
	if (query) {
		return fromEntries(
			Object.entries(query)
				.filter(
					([paramKey, paramValue]) =>
						paramKey
							.toLowerCase()
							.startsWith(QUERY_PARAM.PAX_PREFIX) &&
						!isNaN(Number(paramValue)),
				)
				.map(([paramKey, paramValue]) => [
					paramKey.slice(QUERY_PARAM.PAX_PREFIX.length),
					paramValue,
				]),
		);
	}
	return null;
};

export const getTourDateFromUrl = (location: any, tourId: any) => {
	const query = getQueryObject(location);
	if (query) {
		const queryParam = `${QUERY_PARAM.DATE}.${tourId}`;
		return isQueryValid(query, queryParam) &&
			validateDate(query[queryParam])
			? query[queryParam]
			: null;
	}
	return null;
};

export const getTourTimeFromUrl = (location: any, tourId: any) => {
	const query = getQueryObject(location);
	const queryParam = `${QUERY_PARAM.TIME}.${tourId}`;
	if (isQueryValid(query, queryParam)) {
		const time = query[queryParam];
		if (time === FLEXIBLE_START_TIME) {
			return time;
		} else if (validateTime(time) && typeof time === 'string') {
			return formatToInventoryTime(time);
		}
	}
	return null;
};

export const getTourPaxFromUrl = (location: any, tourId: any) => {
	const query = getQueryObject(location);
	const queryParamPrefix = `${QUERY_PARAM.PAX_PREFIX}`;
	if (query) {
		return fromEntries(
			Object.entries(query)
				.filter(
					([paramKey, paramValue]) =>
						paramKey.toLowerCase().startsWith(queryParamPrefix) &&
						paramKey.toLowerCase().endsWith(tourId) &&
						!isNaN(Number(paramValue)),
				)
				.map(([paramKey, paramValue]) => [
					paramKey.slice(
						queryParamPrefix.length,
						-(String(tourId).length + 1),
					),
					paramValue,
				]),
		);
	}
	return null;
};

export const getSourceFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.SOURCE)
		? query[QUERY_PARAM.SOURCE]
		: '';
};

export const getInstantCheckoutFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.INSTANT_CHECKOUT)
		? query[QUERY_PARAM.INSTANT_CHECKOUT]
		: '';
};

export const getSecureIdFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.SECURE_ID)
		? query[QUERY_PARAM.SECURE_ID]
		: null;
};

export const getRedirectResultFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.REDIRECT_RESULT)
		? query[QUERY_PARAM.REDIRECT_RESULT]
		: null;
};

export const getCouponCodeFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.COUPON_CODE)
		? query[QUERY_PARAM.COUPON_CODE]
		: null;
};

export const getConversionTrackingParameterFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.TRACK_CONVERSION)
		? query[QUERY_PARAM.TRACK_CONVERSION]
		: null;
};

export const getQueryFromURLByName = (location: any, queryParam: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, queryParam) ? query[queryParam] : null;
};

export const getSelectorModalFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.SELECTOR)
		? query[QUERY_PARAM.SELECTOR]
		: null;
};

export const getCreditCashbackFlagFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.CREDIT_CASHBACK)
		? query[QUERY_PARAM.CREDIT_CASHBACK]
		: null;
};

export const getRetainParameterFromUrl = (location: any) => {
	const query = getQueryObject(location);
	return isQueryValid(query, QUERY_PARAM.RETAIN)
		? query[QUERY_PARAM.RETAIN]
		: null;
};

export const getSanitizedPaxQuery = (people: any) => {
	if (hasValidPax(people)) {
		const { selectionMap, groupSize } = people;
		if (groupSize) {
			return {
				[`${QUERY_PARAM.PAX_PREFIX}groupSize`]: groupSize,
			};
		}
		return fromEntries(
			Object.entries(selectionMap).map(([paxType, paxSelection]) => [
				`${QUERY_PARAM.PAX_PREFIX}${paxType.toLowerCase()}`,
				paxSelection,
			]),
		);
	}
	return {};
};

export const getSanitizedTourPaxQuery = (
	people: Record<string, any> | null,
	tourId: string,
) => {
	if (people && hasValidPax(people)) {
		const { selectionMap, groupSize } = people;
		if (groupSize) {
			return {
				[`${QUERY_PARAM.PAX_PREFIX}groupSize.${tourId}`]: groupSize,
			};
		}
		return fromEntries(
			Object.entries(selectionMap).map(([paxType, paxSelection]) => [
				`${QUERY_PARAM.PAX_PREFIX}${paxType.toLowerCase()}.${tourId}`,
				paxSelection,
			]),
		);
	}
	return {};
};

export const getFilters = (location: any) => {
	const queryObject = getQueryObjectFromUrl(
		decodeURIComponent(location.search),
	);
	let filters = {};
	FILTER_AND_SORT_KEYS.forEach(key => {
		if (Object.prototype.hasOwnProperty.call(queryObject, key)) {
			filters = {
				...filters,
				[key]: queryObject[key],
			};
		}
	});
	return filters;
};

export const removeKeysBasedOnConstant = (
	query: Record<string, any>,
	constant: string[],
) => {
	const newQuery = Object.assign({}, query);
	constant.forEach(key => {
		delete newQuery[key];
	});
	return newQuery;
};

export const keepKeysBasedOnConstant = (
	query: Record<string, any>,
	constant: string[],
) => {
	const newQuery = {};

	constant.forEach((key: string) => {
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		if (query[key] !== undefined) newQuery[key] = query[key];
	});
	return newQuery;
};

export const getRewriteNormalisedHrefFromCurrentHref = (href: string) => {
	if (/tour\//.test(href)) return REWRITE_NORMALISED_HREF_MAP.EXPERIENCE_PAGE;

	if (/cities\//.test(href)) return REWRITE_NORMALISED_HREF_MAP.CITY_PAGE;

	if (/category\//.test(href))
		return REWRITE_NORMALISED_HREF_MAP.CATEGORY_PAGE;

	return href;
};

export const replacePageQuery = (
	query: Record<string, any>,
	refreshPage = true,
	options: Record<string, any>,
) => {
	const locationPathName = Router.asPath.split('?')[0];
	const locationHref = Router.pathname;
	if (refreshPage) {
		Router.replace(
			{
				pathname: locationHref,
				query,
			},
			{ pathname: locationPathName, query },
		);
	} else {
		const queryString = getStringifiedQueryFromObject(query, options);
		const url = `${locationHref}${queryString ? `?${queryString}` : ''}`;
		const asPath = `${locationPathName}${
			queryString ? `?${queryString}` : ''
		}`;

		// NextJS internally handles routes for popState using this object (nextHistoryState), and renders the page accordingly.
		// if for any reason popState misbehaves, its probably because NextJS has changed
		// something under the hood (at the time of writing this NextJS was v9.5),
		// check the router module in NextJS and reverify the logic there and match it here.
		const nextHistoryState = {
			as: asPath,
			options: {
				shallow: true,
			},
			url: getRewriteNormalisedHrefFromCurrentHref(url),
			__N: true,
		};
		history.replaceState(nextHistoryState, '', asPath);
	}
};

// @ts-expect-error TS(7006): Parameter 'query' implicitly has an 'any' type.
export const pushPageQuery = (query, refreshPage = true, options) => {
	const url =
		typeof window !== 'undefined' ? window.location?.href : Router.asPath;
	const currentQuery = getQueryObjectFromUrl(url);
	const newQuery = {
		...currentQuery,
		...query,
	};
	const locationPathName = url.split('?')[0];
	const locationHref = Router.pathname;
	if (refreshPage) {
		Router.push(
			{
				pathname: locationHref,
				// @ts-expect-error TS(2345): Argument of type '{ pathname: string; newQuery: an... Remove this comment to see the full error message
				newQuery,
			},
			{ pathname: locationPathName, newQuery },
		);
	} else {
		const queryString = getStringifiedQueryFromObject(newQuery, options);
		const url = `${locationHref}${queryString ? `?${queryString}` : ''}`;
		const asPath = `${locationPathName}${
			queryString ? `?${queryString}` : ''
		}`;

		// NextJS internally handles routes for popState using this object (nextHistoryState), and renders the page accordingly.
		// if for any reason popState misbehaves, its probably because NextJS has changed
		// something under the hood (at the time of writing this NextJS was v9.5),
		// check the router module in NextJS and reverify the logic there and match it here.
		const nextHistoryState = {
			as: asPath,
			options: {
				shallow: true,
			},
			url: getRewriteNormalisedHrefFromCurrentHref(url),
			__N: true,
		};

		history.pushState(nextHistoryState, '', asPath);
	}
};

export const changePageQuery = (
	query: Record<string, any>,
	refreshPage = true,
	isNonBookingFlow = true,
) => {
	if (typeof window === 'undefined') return;

	const currentQuery = getQueryObjectFromUrl(
		typeof window !== 'undefined' ? window.location?.href : Router.asPath,
	);
	const date = read('dateQuery');
	const time = read('timeQuery');
	// @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
	replacePageQuery(
		{
			...currentQuery,
			...query,
			...(isNonBookingFlow && date && { date }),
			...(isNonBookingFlow && time && { time }),
		},
		refreshPage,
	);
};

export const removePageQuery = (queryParam: any) => {
	if (typeof window === 'undefined') return;
	const currentQuery = getQueryObjectFromUrl(window.location?.href);
	const newQuery = fromEntries(
		Object.entries(currentQuery).filter(([param]) => param !== queryParam),
	);
	// @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
	replacePageQuery({ ...newQuery }, false);
};

export const saveAffiliateParamsToCookies = () => {
	const queryParams = getQueryObjectFromUrl(Router.asPath);
	const affiliatePublicKey = queryParams[QUERY_PARAM.AFFILIATE_CODE];
	const partnerTraceId = queryParams[QUERY_PARAM.PARTNER_TRACE_ID];
	const impactClickId = queryParams[QUERY_PARAM.IMPACT_CLICK_ID];
	// Parse utm params from query Params
	const affiliateUtmInfo = Object.entries(queryParams)
		.filter(([key, _value]) => key.indexOf('utm_') > -1)
		.map(([key, value]) => `${key}=${value}`)
		.join('&');

	// Get sub_id for TravelPayouts links
	let travelPayoutsSubID;
	if (queryParams[QUERY_PARAM.SUB_ID]) {
		travelPayoutsSubID = queryParams[QUERY_PARAM.SUB_ID];
	}

	const affiliateCookieExpirationTime = 30 * 24 * 60 * 60; // 30 days
	const affiliateCookie = getCookie(COOKIE.AFFILIATE_CODE);
	const travelPayoutsSubIDCookie = getCookie(COOKIE.TRAVELPAYOUTS_SUB_ID);
	const impactCookie = getCookie(COOKIE.IMPACT_CLICK_ID);

	// Set partner trace ID to cookie - always override even if it exists.
	if (partnerTraceId) {
		setCookie(null, COOKIE.PARTNER_TRACE_ID, partnerTraceId as string, {
			maxAge: affiliateCookieExpirationTime,
			path: '/',
		});
	}

	// Set cookies when affiliate cookie is not present
	if (affiliatePublicKey && !affiliateCookie) {
		// @ts-expect-error TS(2345): Argument of type 'string | (string | null)[]' is n... Remove this comment to see the full error message
		setCookie(null, COOKIE.AFFILIATE_CODE, affiliatePublicKey, {
			maxAge: affiliateCookieExpirationTime,
			path: '/',
		});
		setCookie(null, COOKIE.AFFILIATE_UTM_INFO, affiliateUtmInfo, {
			maxAge: affiliateCookieExpirationTime,
			path: '/',
		});

		if (travelPayoutsSubID && !travelPayoutsSubIDCookie) {
			// @ts-expect-error TS(2345): Argument of type 'string | (string | null)[]' is n... Remove this comment to see the full error message
			setCookie(null, COOKIE.TRAVELPAYOUTS_SUB_ID, travelPayoutsSubID, {
				maxAge: affiliateCookieExpirationTime,
				path: '/',
			});
		}
	}
	// Set cookies when impact cookie is not present or if already set and the cookie value is different then reset the value
	if (
		(impactClickId && !impactCookie) ||
		(impactClickId && impactClickId !== impactCookie)
	) {
		// @ts-expect-error TS(2345): Argument of type 'string | (string | null)[]' is n... Remove this comment to see the full error message
		setCookie(null, COOKIE.IMPACT_CLICK_ID, impactClickId, {
			maxAge: affiliateCookieExpirationTime,
			path: '/',
		});
	}
};

export const removeMultiplePageQueries = (queryParams = []) => {
	const currentQuery = getQueryObjectFromUrl(
		typeof window !== 'undefined' ? window.location?.href : Router.asPath,
	);
	const newQuery = fromEntries(
		Object.entries(currentQuery).filter(
			// @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
			([param]) => !queryParams.includes(param),
		),
	);
	// @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
	replacePageQuery({ ...newQuery }, false);
};

/**
 * Checks if route pathname contains a location
 * @param stringToCheck
 * @param location
 * @return {boolean|*}
 */
export const doesCurrentRouteIncludeString = (
	stringToCheck: any,
	location: any,
) => {
	if (!location) {
		return false;
	}
	const { asPath } = location;
	return asPath?.includes(stringToCheck);
};

export const getHostName = (url: string) => {
	const match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
	if (
		match != null &&
		match.length > 2 &&
		typeof match[2] === 'string' &&
		match[2].length > 0
	) {
		return match[2];
	}
	return null;
};

export const replaceTestingEnvURLwithProductionURL = (url: string) => {
	const hostName = getHostName(url);
	if (hostName) {
		return url.replace(hostName, 'headout.com');
	}
	return url;
};

export const isNumericProductId = (id: number) => !isNaN(id);

export const constructCityURL = ({
	currentCityCode,
	citiesMap,
	lang = 'en',
}: {
	currentCityCode: string;
	citiesMap: Record<string, any>;
	lang?: string;
}) => {
	const apiLang = getApiString(lang);

	if (currentCityCode) {
		return getDecodedUrlSlugs(citiesMap?.[currentCityCode]?.urlSlugs)?.[
			apiLang
		];
	}
	return '/';
};

export const getLocationObjectFromRouter = (
	query: any,
	pathname: any,
	asPath: any,
) => ({
	query,
	pathname,
	search: asPath.indexOf('?') !== -1 ? `?${asPath.split('?')[1]}` : '',
	asPath,
	href: asPath.indexOf('?') === -1 ? asPath : `${asPath.split('?')[0]}`,
});

export const getCurrentUrl = (req: any) =>
	req && `${req.protocol}://${req.headers.host}${req.originalUrl}`;

export const addFilterAndSortingKeys = (query: Record<string, any>) => {
	const newQuery = {};
	FILTER_SORT_AND_OFFSET_KEYS.forEach(key => {
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		if (query[key]) newQuery[key] = query[key];
	});
	return newQuery;
};

export const isHomePageUrl = (pathname: string) =>
	pathname === '/' || pathname === '/[lang]';

export const isCollectionUrl = (pathname: string) =>
	pathname === '/[lang]/collection/[id]';

export const isSubCategoryUrl = (pathname: string) =>
	pathname === '/[lang]/subcategory/[id]';

export const isCategoryUrl = (pathname: string) =>
	pathname === '/[lang]/category/[id]';

export const isListPageUrl = (pathname: string) =>
	isCategoryUrl(pathname) ||
	isSubCategoryUrl(pathname) ||
	isCollectionUrl(pathname);

export const isCityPageUrl = (pathname: string) =>
	pathname === '/[lang]/cities/[city]';

export const isExperiencePageUrl = (pathname: string) =>
	pathname === '/[lang]/tour/[id]';

export const getWhitelistedCanonicalQueryParams = (queryStr: string) => {
	try {
		const queryObject = new URLSearchParams(queryStr);
		// @ts-expect-error TS(2802): Type 'IterableIterator<string>' can only be iterat... Remove this comment to see the full error message
		[...queryObject.keys()].forEach(key => {
			if (!CANONICAL_QUERY_WHITELIST.includes(key))
				queryObject.delete(key);
		});
		const stringQuery = queryObject.toString();
		return stringQuery ? `?${stringQuery}` : '';
	} catch {
		return '';
	}
};

export const getLowerCaseLangCodes = (code: string[]) =>
	code?.map(lang => lang.toLowerCase());

export const addQueryParamsToUrl = (
	url?: string,
	params: Record<string, any> = {},
) => {
	if (url) {
		const searchParamsObject = new URLSearchParams(url.split('?')[1] ?? '');
		if (Object.keys(params).length > 0) {
			for (const property in params) {
				const key = property;
				const value = params[property];
				searchParamsObject.set(key, value);
			}
		}
		const queryString = searchParamsObject.toString().length
			? `?${searchParamsObject.toString()}`
			: '';
		return `${url}${queryString}`;
	}
};

export const checkRouteMatch = (routeToCheck: string, location: BaseRouter) => {
	const { asPath, pathname } = location || {};

	return asPath?.includes(routeToCheck) || pathname?.includes(routeToCheck);
};

export const getNakedDomain = (hostname: string) => {
	if (hostname.includes('localhost'))
		return hostname.split(':').at(0) as string;
	const sliceCount = hostname.includes('deimos.test-headout') ? 2 : 1; // handle ODEs.
	const domainParts = hostname.split('.');
	const nakedDomain =
		domainParts.length === 2
			? hostname
			: hostname.split('.').slice(sliceCount).join('.');
	return nakedDomain;
};

export const isManageBookingPageUrl = (pathname: string) =>
	Boolean(pathname?.includes('/manage-booking/'));

export const isHelpPageUrl = (pathname: string): boolean =>
	Boolean(pathname?.includes('/help'));

export const isProfilePageUrl = (pathname: string): boolean =>
	Boolean(pathname?.includes('/profile'));

export const isConfirmationPageUrl = (pathname: string): boolean =>
	Boolean(pathname?.includes('/confirmation'));

export const getPageNumberFromPageSortingParams = (
	pageSortingParams: string,
	limit: number = NEXT_NEW_PRODUCTS_PAGINATION,
) => {
	try {
		const queryObject = new URLSearchParams(pageSortingParams);
		const pageNo = parseInt(queryObject.get(QUERY_PARAM.LIMIT)!) / limit;
		return pageNo ? pageNo : null;
	} catch (e) {
		return null;
	}
};

export const hasUrlSortingParams = (pageSortingParams: string) => {
	const queryObject = new URLSearchParams(pageSortingParams);
	return (
		queryObject.has(SORT_FILTER_KEYS[0]) ||
		queryObject.has(SORT_FILTER_KEYS[1])
	);
};

export const getSanitizedQueryParams = (
	queryParams: Record<string, any>,
	allowedKeys: string[],
) => {
	let newQueryObj = {};

	Object.keys(queryParams).forEach(key => {
		if (allowedKeys.includes(key)) {
			newQueryObj = {
				...newQueryObj,
				[key]: queryParams[key],
			};
		}
	});

	return newQueryObj;
};
