// @ts-expect-error TS(7016): Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import groupBy from 'lodash.groupby';

import { getFirstAvailableTimeAcrossTours } from 'Utils/bookingFlowUtils';

import { INVENTORY_TYPE, PROFILE_TYPE } from 'Constants/constants';
import { strings } from 'Constants/strings';

import { getMaxSubarrayIncreasingByN } from './arrayUtils';
import { getAMGroupProfile } from './bookingUtils';
import {
	getBillableCurrencyCode,
	getBillablePrice,
	getBreakupObject,
	getDiscount,
	getPricePayable,
} from './breakupUtils';
import { getClarityUrl } from './clarityUtils';
import { format, getLocalTimezone, localDateTimetoJSDate } from './dateUtils';
import { getClientCurrency, getTour } from './pricingUtils';
import { getSmartLookUrl } from './smartLookUtils';
import { capitalizeFirstLetter } from './stringUtils';

export const getAMPeopleProfile = ({ groupSize, seatMapInfo }: any) => {
	const type =
		groupSize > 0 ? PROFILE_TYPE.PER_GROUP : PROFILE_TYPE.PER_PERSON;
	const groupProfile = getAMGroupProfile(groupSize);
	const personProfiles = [
		{
			type: 'ADULT',
			people: seatMapInfo.length,
			priceProfile: seatMapInfo
				? seatMapInfo.map((x: any) => x.price)
				: null,
		},
	];
	return {
		type,
		personProfiles,
		groupProfile,
	};
};

export const getBookables = (seatMapInfo: any) => {
	if (seatMapInfo) {
		return seatMapInfo.map((seatInfo: any) => ({
			bookableId: seatInfo.id,
			count: 1,
			unitPrice: seatInfo.price,
		}));
	}
	return null;
};

export const getSeatmapBookingDetails = ({
	pricing,
	booking,
	applyingPromo = false,
	comment,
}: any) => {
	const {
		id,
		selectedDate,
		selectedTime,
		selectedTourId,
		groupSize,
		promoObject,
		seatMapInfo,
		itineraryComment,
	} = booking;
	const appliedPromoCode =
		applyingPromo && promoObject && promoObject?.isApplied
			? promoObject?.appliedPromoCode
			: null;
	const couponCode =
		applyingPromo && promoObject
			? promoObject?.promoCode
			: appliedPromoCode;
	const peopleProfile = getAMPeopleProfile({
		groupSize,
		seatMapInfo,
	});
	const inventoryTime = getFirstAvailableTimeAcrossTours(
		pricing,
		selectedDate,
		selectedTime,
	);
	const breakup = getBreakupObject(booking);
	const pricePayable = Number(getPricePayable(breakup));
	const bookables = getBookables(seatMapInfo);
	const clientLanguage = booking?.language;
	const clientTimezone = getLocalTimezone();
	const clientCurrency = getClientCurrency(pricing);
	const billableValue = getBillablePrice(breakup);
	const billableCurrency = getBillableCurrencyCode(breakup);

	return {
		comment: `${getSmartLookUrl() || ''} | ${
			getClarityUrl() || ''
		} ${comment}`,
		inventoryDate: selectedDate,
		inventoryTime,
		tourGroupId: id,
		tourId: selectedTourId,
		peopleProfile,
		couponCode,
		pricePayable,
		seatInfos: seatMapInfo,
		bookables,
		clientCurrency,
		clientLanguage,
		clientTimezone,
		billableValue,
		billableCurrency,
		itineraryComment,
	};
};

export const getNetPricePayable = ({
	// @ts-expect-error TS(7031): Binding element 'booking' implicitly has an 'any' ... Remove this comment to see the full error message
	booking,
	deductPromoCodeDiscount = true,
	walletCredits = 0,
}) => {
	const { seatMapInfo, breakup } = booking;
	let totalPrice = 0;
	seatMapInfo.forEach((seatInfo: any) => {
		totalPrice += seatInfo.price;
	});

	const promoDiscount = getDiscount(breakup);
	if (deductPromoCodeDiscount && promoDiscount) {
		totalPrice -= promoDiscount;
	}
	totalPrice = Math.max(totalPrice - walletCredits, 0);

	return totalPrice;
};

/**
 * @param booking
 * @returns {Array} [{number: 2, type: ADULT, price: 80.50},...] or [{number: 3, type: Tickets, price: 200}]
 */
export const getPricesWithSelections = (booking: any) => {
	const { seatMapInfo } = booking;
	// @ts-expect-error TS(7034): Variable 'pricesWithSelections' implicitly has typ... Remove this comment to see the full error message
	let pricesWithSelections = [];
	const groupedSelectionSeats = groupBy(seatMapInfo, 'seatSection');
	Object.keys(groupedSelectionSeats).forEach(selection => {
		const selectionMapInfo = groupedSelectionSeats[selection];
		const groupedPriceSelection = groupBy(selectionMapInfo, 'price');
		Object.values(groupedPriceSelection).forEach(section => {
			pricesWithSelections.push({
				number: (section as any).length,
				type: `${(section as any)?.[0]?.seatSection} ${
					(section as any).length > 1 ? 'seats' : 'seat'
				}`,
				price: (section as any)?.[0]?.price,
				originalPrice: (section as any)?.[0]?.price,
			});
		});
	});
	// @ts-expect-error TS(7005): Variable 'pricesWithSelections' implicitly has an ... Remove this comment to see the full error message
	return pricesWithSelections;
};

export const getSlotDescription = (booking: any, pricing: any) => {
	const { selectedDate, selectedTime } = booking;
	const dateTime = localDateTimetoJSDate(selectedDate, selectedTime);
	const date = format(dateTime, 'd mmm, dddd');
	const time = format(dateTime, 'h:MM tt');
	const tourId = booking?.selectedTourId;

	// Handle case of embedded seatmaps - no tour is selected.
	if (!tourId) {
		return time ? `${date}, ${time}` : `${date}`;
	}
	const tour = getTour(pricing, tourId);
	const inventoryType = tour?.inventoryType;
	const isFixedStart =
		inventoryType === INVENTORY_TYPE.FIXED_START_FIXED_DURATION ||
		inventoryType === INVENTORY_TYPE.FIXED_START_FLEXIBLE_DURATION;

	return isFixedStart ? `${date},   ${time}` : `${date}`;
};

export const getTicketDescription = (booking: any) => {
	const { seatMapInfo } = booking;
	let seatsList = '';
	const groupedSeatSelections = groupBy(seatMapInfo, 'seatSection');
	Object.keys(groupedSeatSelections).forEach(seatSection => {
		const seatInfos = groupedSeatSelections[seatSection];
		seatsList = seatsList.concat(
			capitalizeFirstLetter(seatSection),
			seatInfos
				.map((x: any) => ''.concat(' ', x.seatRow, x.seatNumber))
				.join(','),
			'   ',
		);
	});

	return `${seatsList}`;
};

/**
 * @param booking
 * @returns string 2 Adult, 1 Child or 3 tickets
 */
export const getPeopleDescription = (booking: any) => {
	const { seatMapInfo } = booking;
	const numPeople = seatMapInfo.length;
	return `${numPeople} ${numPeople > 1 ? 'Adults' : 'Adult'}`;
};

export const filterSeatMapBySlot = ({
	availableSeatsMap,
	date,
	time,
	tourId,
}: any) => {
	if (availableSeatsMap && availableSeatsMap?.[date]) {
		const seatMapData = availableSeatsMap?.[date]?.data;
		return seatMapData.filter((item: any) => {
			const hasAvailableSeats = item?.availableSeats
				? item?.availableSeats.length
				: false;
			if (time) {
				return (
					hasAvailableSeats &&
					item?.tourId === tourId &&
					item?.startTime === time
				);
			}
			return hasAvailableSeats && item?.tourId === tourId;
		});
	}
	return [];
};

// @ts-expect-error TS(7031): Binding element 'time' implicitly has an 'any' typ... Remove this comment to see the full error message
export const getAvailableTourId = ({ time, date }, pricing) => {
	return pricing?.availabilities
		.filter((x: any) => x?.startDate === date)
		.filter((x: any) => x?.startTime === time)?.[0]?.tourId;
};

export const getSeatDetails = ({
	availableSeatsMap,
	nPeople,
	date,
	time,
	tourId,
}: any) => {
	const filteredSeats = filterSeatMapBySlot({
		availableSeatsMap,
		date,
		time,
		tourId,
	})?.[0];
	if (!filteredSeats) {
		return '';
	}
	const availableSeats = filteredSeats?.availableSeats;
	const groupedRows = groupBy(availableSeats, 'rowName');
	let index = 0;
	const validRow = groupedRows.find((items: any) => {
		const { beginIndex, maxCount } = getMaxSubarrayIncreasingByN(
			1,
			items.map((item: any) => item?.rowNumber),
		);
		if (maxCount >= nPeople) {
			index = beginIndex;
			return true;
		}
		return false;
	});
	if (validRow) {
		return validRow
			.slice(index, index + nPeople)
			.map((item: any) => item?.code)
			.join();
	}
	return availableSeats
		.slice(0, Math.min(nPeople, availableSeats.length))
		.map((item: any) => item?.code)
		.join();
};

export const getStructuredSelectedSeats = (seatsInfo: any) => {
	// @ts-expect-error TS(7034): Variable 'structuredSelectedSeats' implicitly has ... Remove this comment to see the full error message
	const structuredSelectedSeats = [];
	seatsInfo.forEach((seat: any) => {
		structuredSelectedSeats.push(seat.id);
	});
	// @ts-expect-error TS(7005): Variable 'structuredSelectedSeats' implicitly has ... Remove this comment to see the full error message
	return structuredSelectedSeats;
};

export const getGroupedSelectedSeats = (seatsInfo: any) => {
	let groupedSelectedSeats = {};
	seatsInfo.forEach((seat: any) => {
		groupedSelectedSeats = {
			...groupedSelectedSeats,
			[seat]: 1,
		};
	});
	return groupedSelectedSeats;
};

export const getSeatSelectionInfo = (seatsSelected: any) => {
	return seatsSelected.length > 0
		? strings.formatString(
				strings.VPSM_SEATS_SELECTED[
					seatsSelected.length > 1 ? 'PLURAL' : 'SINGLE'
				],
				seatsSelected.length,
		  )
		: strings.VPSM_YOUR_SEATS;
};

export const getSeatSelectionValidationData = (validationData: any) => {
	const { invalidBookableList } = validationData;
	const invalidSeatReasonMap = {};
	invalidBookableList.forEach((invalidSeatData: any) => {
		const bookableIds = invalidSeatData.invalidBookableIds;
		const unvalidityReason = invalidSeatData.bookableInvalidityReason;
		const priority = invalidSeatData.priority;
		bookableIds.forEach((seat: any) => {
			if (
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				!invalidSeatReasonMap[seat] ||
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				invalidSeatReasonMap[seat].priority > priority
			) {
				// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
				invalidSeatReasonMap[seat] = {
					unvalidityReason,
					priority,
				};
			}
		});
	});
	return {
		seatsSelectionValidationInfo: invalidSeatReasonMap,
		isSelectedSeatsValid: invalidBookableList.length === 0,
	};
};

export const getOrderedSeatSelection = (seats: any, validationData: any) => {
	if (!validationData || (validationData && validationData.length === 0))
		return seats;
	const invalidSeats = Object.keys(validationData);
	seats.sort((x: any, y: any) => {
		if (invalidSeats.includes(x.id) && invalidSeats.includes(y.id)) {
			return 0;
		} else if (
			invalidSeats.includes(x.id) &&
			!invalidSeats.includes(y.id)
		) {
			return -1;
		} else if (
			!invalidSeats.includes(x.id) &&
			invalidSeats.includes(y.id)
		) {
			return 1;
		}
		return 0;
	});
	return seats;
};
