import dateFormat from 'dateformat';
import type { Dayjs } from 'dayjs';

import dayjs from 'Utils/dayjsUtil';

import { strings } from 'Constants/strings';

/**
 * Converts the given date to format provided
 * @param date jsDate or any date string that can be parsed by Date.parse() in javascript
 * @param formatAsString reference: https://github.com/felixge/node-dateformat
 * @param timeZone City Timezone, pass 'true' for UTC
 */
export const format = (date: any, formatAsString: any, timeZone?: boolean) => {
	if (!date || !formatAsString) return '';

	return dateFormat(date, formatAsString, timeZone);
};

/**
 * Converts the given date to format provided
 * @param timeAsString in HH:MM:SS format
 * @param formatAsString reference: https://github.com/felixge/node-dateformat
 */
export const formatTime = (
	timeAsString?: string,
	formatAsString = 'h:MM TT',
) => {
	const dateAsString = format(new Date(), 'yyyy-mm-dd');
	const jsDate = localDateTimetoJSDate(dateAsString, timeAsString);
	return format(jsDate, formatAsString);
};

export const formatDurationToHoursMinutes = (durationInMillis: number) => {
	const duration = dayjs.duration(durationInMillis);
	const days = duration.days();
	let hours = duration.hours();
	const minutes = duration.minutes();
	let res = '';
	if (hours + days * 24 <= 48) {
		hours += days * 24;
	} else {
		res = `${days} days`;
	}

	if (hours > 0) {
		res = `${res} ${hours}${
			hours > 1
				? strings.BOOKING_PAGE_TIME.HOURS
				: strings.BOOKING_PAGE_TIME.HOUR
		}`;
	}

	if (minutes > 0) {
		res = `${res} ${minutes}${
			minutes > 1
				? strings.BOOKING_PAGE_TIME.MINUTES
				: strings.BOOKING_PAGE_TIME.MINUTE
		}`;
	}

	return res.trim();
};

export const formatDateToYYYYMMDD = (myDate: any) => {
	return `${myDate.getFullYear()}-${`0${myDate.getMonth() + 1}`.slice(
		-2,
	)}-${`0${myDate.getDate()}`.slice(-2)}`;
};

export const getHumanReadableDate = (
	formattedDate: string,
	lang?: string,
	outputFormat = 'Do MMM',
	inputFormat = 'YYYY-MM-DD',
) => {
	return dayjs(formattedDate, inputFormat, lang).format(outputFormat);
};

export const getDayJSLocaleAndHumanReadableDate = (
	formattedDate: string,
	lang: string,
) => {
	dayjs.locale(lang);
	return getHumanReadableDate(formattedDate);
};

export const getHumanReadableTime = (
	formattedTime?: string,
	lang?: string,
	outputFormat = 'LT',
	inputFormat?: string,
) => {
	const format =
		formattedTime && formattedTime?.length <= 5 ? 'HH:mm' : 'HH:mm:ss';
	return dayjs(formattedTime, inputFormat ?? format, lang).format(
		outputFormat,
	);
};

export const getHumanReadableDateTime = (inventory: any) => {
	const humanReadableDate = getHumanReadableDate(inventory?.startDate);
	const humanReadableTime = getHumanReadableTime(inventory?.startTime);

	return `${humanReadableDate} at ${humanReadableTime}`;
};

export const localDateToJsDate = (dateString?: string) => {
	if (!dateString) dayjs().toDate();

	return dayjs(dateString, 'YYYY-MM-DD').toDate();
};

export const localDateTimetoJSDate = (
	dateString: string,
	timeString?: string,
) => {
	if (!timeString) {
		return dayjs(`${dateString}`, 'YYYY-MM-DD');
	}

	return dayjs(
		`${dateString} ${timeString ?? ''}`,
		'YYYY-MM-DD HH:mm:ss',
	).toDate();
};

export const localDateTimeToDayJSDate = (
	dateString: string,
	timeString?: string,
) => {
	if (!timeString) {
		return dayjs(`${dateString}`, 'YYYY-MM-DD');
	}

	return dayjs(`${dateString} ${timeString ?? ''}`, 'YYYY-MM-DD HH:mm:ss');
};

export const formatUsingDayJS = (
	date: string | Date | Dayjs,
	formatString: string,
) => {
	return dayjs(date).format(formatString).toString().replace('.', '');
};

export const addDay = (date: Date | string, nDaysToAdd: number) => {
	return dayjs(date).add(nDaysToAdd, 'days').toDate();
};

export const timeComparator = (a: any, b: any) => {
	return dayjs(a.key, 'H:m:s').unix() - dayjs(b.key, 'H:m:s').unix();
};

export const getLocalTimezoneSuffix = () => {
	const timezoneAndDateString = dayjs()
		.tz(dayjs.tz.guess())
		.format('DD/MM/YYYY z');
	const timezoneSuffix = timezoneAndDateString.split(' ')[1];
	const finalSuffix = isNaN(parseInt(timezoneSuffix, 0))
		? timezoneSuffix
		: `UTC${dayjs().format('Z')}`;
	return ` (${finalSuffix})`;
};

export const getLocalTimezone = () => {
	return dayjs.tz.guess();
};

export const nextDateFormatted = (
	dateString: string | Date,
	format = 'YYYY-MM-DD',
) => {
	const dateDayJS = dayjs(dateString, 'YYYY-MM-DD');
	const nextDayDayJS = dateDayJS.add(1, 'days');
	return nextDayDayJS.format(format);
};

export const formatInMonthTitleFormat = (date: string) =>
	dayjs(date).format('MMMM YYYY');

export const formatToInventoryTime = (time: string | null) =>
	dayjs(time, 'HH:mm').format('HH:mm:ss');

export const getLastAndFirstDayOfMonthAndYear = (
	year: number,
	month: number,
	isLastDayDate: boolean,
) =>
	dayjs(
		new Date(
			year,
			isLastDayDate ? month : month - 1,
			isLastDayDate ? 0 : 1,
		),
	).format('YYYY-MM-DD');

export const computeDaysTillExperience = (
	experienceDate: string,
	timeZone: string,
) => dayjs(experienceDate).diff(dayjs().tz(timeZone), 'days');

export const computeDaysTillExperienceWithTime = ({
	experienceDate,
	experienceTime,
	timeZone,
}: {
	experienceDate?: string;
	experienceTime: string;
	timeZone: string;
}) =>
	dayjs(`${experienceDate} ${experienceTime}`).diff(
		dayjs().tz(timeZone),
		'days',
	);

export const getDurationInHours = (duration: number) =>
	Math.round(duration / 60);

export const getDurationInDays = (duration: number) =>
	Math.round(duration / 1440);

export const toLocalDateTime = (
	dateStr: string,
	timeStr: string,
	originalTimezone: string,
) => {
	const dateObj = dayjs(`${dateStr} ${timeStr}`).tz(originalTimezone).local();
	return {
		date: dateObj.format('YYYY-MM-DD'),
		time: dateObj.format('HH:mm:ss'),
	};
};

export const datetimeStrToTimezone = ({
	dateStr,
	timeStr,
	targetTimezone,
	originalTimezone,
}: {
	dateStr: string;
	timeStr: string;
	targetTimezone: string;
	originalTimezone: string;
}) => {
	const dateObj = dayjs(`${dateStr} ${timeStr}`)
		.tz(originalTimezone)
		.tz(targetTimezone);
	return {
		date: dateObj.format('YYYY-MM-DD'),
		time: dateObj.format('HH:mm:ss'),
	};
};

export const getCancellationDayAndTime = ({
	selectedDate,
	selectedTime,
	durationInHours,
}: {
	selectedDate: string;
	selectedTime: string;
	durationInHours: number;
}) => {
	const cancellationDateTimeObj = localDateTimeToDayJSDate(
		selectedDate,
		selectedTime,
	).subtract(durationInHours, 'hour');
	return {
		date: cancellationDateTimeObj.format('D'),
		time: cancellationDateTimeObj.format('LT'),
		month: cancellationDateTimeObj.format('MMMM'),
		year: cancellationDateTimeObj.format('YYYY'),
	};
};

export const experienceDateTimeToUTC = ({
	dateStr,
	timeStr,
	targetTimezone,
	originalTimezone,
}: {
	dateStr: string;
	timeStr: string;
	targetTimezone: string;
	originalTimezone: string;
}) => {
	const UTCDayJSObj = dayjs(`${dateStr} ${timeStr}`)
		.tz(originalTimezone)
		.tz(targetTimezone);
	return UTCDayJSObj.format();
};

/**
 * Converts the given time - HH:MM:SS to localised time - HH:MM
 * @param {string} timeAsString in HH:MM:SS format
 * @param {string} lang language code - en, fr, it, de, etc.
 */
export const formatTimeLocalised = (
	timeAsString: string | undefined,
	lang: string | undefined,
) => {
	const localLocale = dayjs(timeAsString, 'hh:mm:ss');
	if (lang) {
		localLocale.locale(lang);
	}

	return localLocale.format('LT');
};

export const formatToDay = (dayJSDate: any) =>
	dayJSDate.format('ddd').replace('.', '');

/* If the first available date is today or tomorrow, replace day with it
 * */
export const getDayName = (dayJSDate: any) => {
	const today = dayjs().startOf('day');
	const tomorrow = today.clone().add(1, 'days').startOf('day');
	if (dayJSDate.isSame(today, 'd')) {
		return strings.VPDB_TODAY;
	}
	if (dayJSDate.isSame(tomorrow, 'd')) {
		return strings.VPDB_TOM;
	}
	return formatToDay(dayJSDate);
};

export const checkIfDateInBetweenDateRange = ({
	date,
	startDate,
	endDate,
}: {
	date: string;
	startDate: string;
	endDate: string;
}) => {
	return dayjs(date).isBetween(dayjs(startDate), dayjs(endDate));
};

export const sortDateArray = (dates: string[]) =>
	dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

export const isDateValid = (date: string) => dayjs(date).isValid();

const getPaddedNumber = (value: number) => (value < 10 ? `0${value}` : value);

export const getHumanReadableDuration = (durationInMilliseconds: number) => {
	const durationInSeconds = Math.floor(durationInMilliseconds / 1000);
	const hours = Math.floor(durationInSeconds / 3600);
	const minutes = Math.floor(durationInSeconds / 60) % 60;
	const seconds = durationInSeconds % 60;
	return durationInSeconds > 3600
		? `${getPaddedNumber(hours)}:${getPaddedNumber(
				minutes,
		  )}:${getPaddedNumber(seconds)}`
		: `${getPaddedNumber(minutes)}:${getPaddedNumber(seconds)}`;
};

export const calculateTimeLeft = (expiryTimeDateObject: any) => {
	let currentTime = new Date();
	return (
		Math.floor(
			(expiryTimeDateObject.valueOf() - currentTime.valueOf()) / 1000,
		) * 1000
	);
};

export const getCurrentDateInTimezone = (targetTimezone: string) =>
	dayjs().tz(targetTimezone);

export const checkLastVisitedInDays = (lastVisited: number, days: number) => {
	return Date.now() - lastVisited < days * 24 * 60 * 60 * 1000;
};

export const getWeekdayLocaleMap = ({
	currentLang = 'en',
	short = false,
}: {
	currentLang?: string;
	short?: boolean;
}) => {
	const weekdaysInEnglish = dayjs().locale('en').localeData().weekdays();
	const weekdaysInLocale = dayjs()
		.locale(currentLang)
		.localeData()
		[short ? 'weekdaysShort' : 'weekdays']();
	let map: { [key: string]: string } = {};
	weekdaysInEnglish.forEach((w, i) => {
		map[w.toLocaleLowerCase()] = weekdaysInLocale[i];
	});
	return map;
};

export const isPastDate = (date: string, timezone: string) => {
	const nowInTimezone = dayjs.tz(dayjs(), timezone);
	return dayjs.tz(date, timezone).isBefore(nowInTimezone);
};

export const countNoOfDaysFromToday = (targetDate: string) => {
	const currentDate = dayjs().format('YYYY-MM-DD');
	//this includes the present day & excludes the targetDate
	return dayjs(targetDate, 'YYYY-MM-DD').diff(currentDate, 'day');
};

export const getDateWeekRange = (selectedDate: string) => ({
	fromDate: dayjs(selectedDate).subtract(6, 'day').format('YYYY-MM-DD'),
	toDate: dayjs(selectedDate).add(6, 'day').format('YYYY-MM-DD'),
});

export const countNoOfDaysFromDate = (date1: string, date2: string) =>
	dayjs(date1, 'YYYY-MM-DD').diff(dayjs(date2).format('YYYY-MM-DD'), 'day');

export function categorizeDates(
	availableDates: string[],
	timeZone: string,
): {
	today: string | null;
	tomorrow: string | null;
	otherDates: string[];
} {
	const result: {
		today: string | null;
		tomorrow: string | null;
		otherDates: string[];
	} = {
		today: null,
		tomorrow: null,
		otherDates: [],
	};

	const currentDate = getCurrentDateInTimezone(timeZone);

	const today = dayjs(currentDate).format('YYYY-MM-DD');
	const tomorrow = dayjs(addDay(today, 1)).format('YYYY-MM-DD');

	availableDates.forEach((dateStr: string) => {
		if (dateStr === today) {
			result.today = dateStr;
		} else if (dateStr === tomorrow) {
			result.tomorrow = dateStr;
		} else {
			result.otherDates.push(dateStr);
			return;
		}
	});

	return result;
}

export const getNextTwoDates = (date: string) => {
	const formattedDate = dayjs(date);
	const tomorrow = formattedDate.add(1, 'day').format('YYYY-MM-DD');
	const dayAfter = formattedDate.add(2, 'day').format('YYYY-MM-DD');

	return [date, tomorrow, dayAfter];
};

export function formatDate(date: string, timeZone: string) {
	if (!date) return;

	const currentDate = getCurrentDateInTimezone(timeZone);

	const today = dayjs(currentDate).format('YYYY-MM-DD');
	const tomorrow = dayjs(addDay(today, 1)).format('YYYY-MM-DD');

	if (date === today) {
		return `${strings.VPDB_TODAY}, ${dayjs(today).format('DD MMM, YYYY')}`;
	} else if (date === tomorrow) {
		return `${strings.VPDB_TOM}, ${dayjs(tomorrow).format('DD MMM, YYYY')}`;
	} else {
		return dayjs(date).format('DD MMM, YYYY');
	}
}

export const getDateMonth = (date: string) => dayjs(date).format('DD MMMM');

export const generateNextXDays = ({
	numberOfDays,
	startDate,
	dayFormat = 'ddd',
	dateFormat = 'YYYY-MM-DD',
}: {
	numberOfDays: number;
	startDate?: string;
	dayFormat?: string;
	dateFormat?: string;
}) => {
	if (!startDate) return [];
	const days = [];
	for (let i = 0; i < numberOfDays; i++) {
		const day = dayjs(startDate).add(i, 'day');
		days.push({
			weekday: day.format(dayFormat),
			date: day.format(dateFormat),
		});
	}
	return days;
};

const rtfUnitTypes = <const>[
	'year',
	'quarter',
	'month',
	'week',
	'day',
	'hour',
	'minute',
	'second',
];
const localeMatcherType = <const>['best fit', 'lookup'];
const numericType = <const>['always', 'auto'];
const styleType = <const>['long', 'short', 'narrow'];

interface LocalisedRelativeTimeFormat {
	locale: string;
	value: number;
	unit: (typeof rtfUnitTypes)[number];
	localeMatcher?: (typeof localeMatcherType)[number];
	numeric?: (typeof numericType)[number];
	style?: (typeof styleType)[number];
	formatToParts?: boolean;
}

export const localisedRelativeTimeFormat = ({
	locale,
	value,
	unit,
	formatToParts = false,
	localeMatcher = 'lookup',
	numeric = 'auto',
	style = 'short',
}: LocalisedRelativeTimeFormat) => {
	try {
		const rtf = new Intl.RelativeTimeFormat(locale, {
			localeMatcher,
			numeric,
			style,
		});
		return formatToParts
			? rtf.formatToParts(value, unit) || []
			: rtf.format(value, unit);
	} catch (e) {
		return '';
	}
};

export const formatDurationToString = ({
	hour,
	minute,
	lang = 'en',
}: {
	hour: number | null;
	minute: number | null;
	lang?: string;
}) => {
	let res = '';
	if (hour) {
		const hourParts = localisedRelativeTimeFormat({
			locale: lang,
			unit: 'hour',
			value: hour,
			formatToParts: true,
		});
		res +=
			typeof hourParts === 'string'
				? hourParts
				: formatPartsToDuration(hourParts);
	}
	if (minute) {
		const minuteParts = localisedRelativeTimeFormat({
			locale: lang,
			unit: 'minute',
			value: minute,
			formatToParts: true,
		});
		res +=
			typeof minuteParts === 'string'
				? ` ${minuteParts}`
				: ` ${formatPartsToDuration(minuteParts)}`;
	}
	return res;
};

const formatPartsToDuration = (arr: Array<Intl.RelativeTimeFormatPart>) => {
	if (arr.length === 3) {
		const [, value, unit] = arr || [];
		return `${value?.value}${unit?.value}`;
	} else {
		const [value, unit] = arr || [];
		return `${value?.value}${unit?.value}`;
	}
};
