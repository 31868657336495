import { FEE_LABELS } from 'Constants/constants';
import { strings } from 'Constants/strings';

enum pricePaybleDiscountTypes {
	WALLET = 'WALLET',
	COUPON_DISCOUNT = 'COUPON_DISCOUNT',
	HEADOUT_DISCOUNT = 'HEADOUT_DISCOUNT',
	MEMBERSHIP_DISCOUNT = 'MEMBERSHIP_DISCOUNT',
}

export const getBreakupObject = (booking: any) =>
	booking?.breakup?.[String(booking?.selectedVariantId)];

export const getPriceBreakup = (breakup: any) => {
	if (!breakup) return undefined;
	return breakup?.priceBreakup ? breakup?.priceBreakup : undefined;
};

export const getPricePayableBreakup = (breakup: any) =>
	breakup?.priceBreakup?.pricePayableBreakup;

export const getVariantBookingPriceBreakupList = (breakup: any) =>
	breakup?.priceBreakup?.variantBookingPriceBreakupList;

export const getBookingPriceBreakupList = (breakup: any) =>
	getVariantBookingPriceBreakupList(breakup)?.[0]?.bookingPriceBreakupList;

export const getFirstBookingPricePayableBreakup = (breakup: any) =>
	getBookingPriceBreakupList(breakup)?.[0]?.pricePayableBreakup;

export const getBookingPricePayableBreakup = (breakup: any, tourIndex: any) =>
	getBookingPriceBreakupList(breakup)?.[tourIndex]?.pricePayableBreakup;

export const getBookingPriceBreakup = (breakup: any, tourId: string) =>
	getBookingPriceBreakupList(breakup)?.find(
		(paxBreakup: any) => paxBreakup?.tourId === Number(tourId),
	);

export const getBookingPriceBreakupByPaxType = (breakup: any, tourId: string) =>
	getBookingPriceBreakup(breakup, tourId)?.priceBreakupByPaxType;

export const getPricePayableUsd = (booking: any) => {
	if (!booking) return null;
	return booking?.bookingResponse?.pricePayableUsd
		? booking?.bookingResponse?.pricePayableUsd
		: null;
};

export const getAdditionalInfoBreakup = (breakup: any) => {
	if (!breakup) return undefined;
	return breakup?.priceBreakup?.additionalInfo
		? breakup?.priceBreakup?.additionalInfo
		: undefined;
};

// Wallet credits that will be used for current booking.
export const getWalletCreditsUsedForBooking = (breakup: any) => {
	if (!breakup) return null;
	const pricePayableBreakup = getPricePayableBreakup(breakup);
	const walletObj = pricePayableBreakup
		? pricePayableBreakup.find(
				(item: any) => item?.type === pricePaybleDiscountTypes.WALLET,
		  )
		: undefined;
	return walletObj ? walletObj?.value * -1 : undefined;
};

export const getCashbackAmount = (breakup: any) => {
	if (!breakup) return null;
	const additionalInfo = getAdditionalInfoBreakup(breakup);
	const cashbackObj = additionalInfo
		? additionalInfo.find((item: any) => item?.type === 'CASHBACK')
		: null;
	return cashbackObj ? cashbackObj?.value : null;
};

export const getProductCashbackAmount = (breakup: any) => {
	if (!breakup) return null;
	const additionalInfo = getAdditionalInfoBreakup(breakup);
	const cashbackObj = additionalInfo
		? additionalInfo.find((item: any) => item?.type === 'CASHBACK')
		: undefined;
	const breakupDetails = cashbackObj
		? cashbackObj?.breakupDetails
		: undefined;
	const productCashback = breakupDetails
		? breakupDetails.find((item: any) => item?.name === 'Product Cashback')
		: undefined;
	return productCashback ? productCashback?.value : undefined;
};

export const getCouponCashbackAmount = (breakup: any) => {
	if (!breakup) return undefined;
	const additionalInfo = getAdditionalInfoBreakup(breakup);
	const cashbackObj = additionalInfo
		? additionalInfo.find((item: any) => item?.type === 'CASHBACK')
		: undefined;
	const breakupDetails = cashbackObj
		? cashbackObj?.breakupDetails
		: undefined;
	const couponCashback = breakupDetails
		? breakupDetails.find((item: any) => item?.name === 'Coupon Cashback')
		: undefined;
	return couponCashback ? couponCashback?.value : undefined;
};

export const getDiscount = (breakup: any) => {
	if (!breakup) return null;
	const pricePayableBreakup = getPricePayableBreakup(breakup);
	const discountObj = pricePayableBreakup
		? pricePayableBreakup?.find(
				(item: any) =>
					item?.type === pricePaybleDiscountTypes.COUPON_DISCOUNT,
		  )
		: null;
	return discountObj ? discountObj?.value * -1 : null;
};

export const getInventoryDiscount = (breakup: any) => {
	if (!breakup) return null;
	const pricePayableBreakup = getPricePayableBreakup(breakup);
	const discountObj = pricePayableBreakup
		? pricePayableBreakup.find(
				(item: any) =>
					item?.type === pricePaybleDiscountTypes.HEADOUT_DISCOUNT,
		  )
		: null;
	return discountObj ? discountObj?.value * -1 : null;
};

export const getExtraChargesList = (breakup: any) => {
	if (!breakup) return undefined;
	const pricePayableBreakup = getPricePayableBreakup(breakup);
	let extraCharges = pricePayableBreakup?.find(
		(item: any) => item?.type === 'EXTRA_CHARGES',
	);
	if (!extraCharges) {
		const bookingPricePayable = getFirstBookingPricePayableBreakup(breakup);
		extraCharges = bookingPricePayable?.find(
			(item: any) => item?.type === 'EXTRA_CHARGES',
		);
	}
	return extraCharges?.breakupDetails;
};

export const getBillableCurrency = (breakup: any) => {
	if (!breakup) return null;

	return breakup?.billablePrice?.currency
		? breakup?.billablePrice?.currency
		: null;
};

export const getBillableCurrencyCode = (breakup: any) =>
	breakup?.billablePrice?.currency?.code || null;

export const getBillablePrice = (breakup: any) => {
	if (!breakup) return null;

	const billablePrice = breakup?.billablePrice?.value;

	return billablePrice || billablePrice === 0 ? billablePrice : null;
};

export const getCurrency = (breakup: any) => {
	if (!breakup) return undefined;
	return breakup?.priceBreakup?.currency
		? breakup?.priceBreakup?.currency
		: undefined;
};

export const getPricePayable = (breakup: any) => {
	if (!breakup) return null;

	const pricePayable = breakup?.priceBreakup?.pricePayable;

	return pricePayable || pricePayable === 0 ? pricePayable : null;
};

export const getPriceDelta = (breakup: any) => {
	if (!breakup) return null;

	return breakup?.priceDelta;
};

export const getConversionString = (breakup: any) => {
	if (!breakup) return undefined;
	const currency = getCurrency(breakup);
	const price = getPricePayable(breakup);
	const bCurrency = getBillableCurrency(breakup);
	const bPrice = getBillablePrice(breakup);
	if (
		!currency ||
		!bCurrency ||
		!price ||
		!bPrice ||
		currency?.code === bCurrency?.code
	)
		return undefined;
	return parseFloat(price) >= parseFloat(bPrice)
		? // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
		  `1 ${bCurrency?.symbol} = ${parseFloat(price / bPrice).toFixed(4)} ${
				currency?.symbol
		  }`
		: // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
		  `1 ${currency?.symbol} = ${parseFloat(bPrice / price).toFixed(4)} ${
				bCurrency?.symbol
		  }`;
};

export const getOriginalPrice = (breakup: any) => {
	if (!breakup) return undefined;
	return breakup?.priceBreakup?.originalPrice
		? breakup?.priceBreakup?.originalPrice
		: undefined;
};

export const getFinalPrice = (breakup: any) => {
	if (!breakup) return undefined;
	return breakup?.priceBreakup?.finalPrice
		? breakup?.priceBreakup?.finalPrice
		: undefined;
};

export const getUsingCoupon = (breakup: any) => {
	if (!breakup) return undefined;
	return breakup?.usingCoupon ? breakup?.usingCoupon : undefined;
};

export const getCouponError = (breakup: any) => {
	if (!breakup) return undefined;
	return breakup?.couponError ? breakup?.couponError : undefined;
};

export const getRetailPriceInUSD = (breakup: any) => {
	if (!breakup) return undefined;
	return breakup?.breakupMetaData?.totalRetailPriceInUSD
		? breakup?.breakupMetaData?.totalRetailPriceInUSD
		: undefined;
};

/* Saved price =  inventory discount + coupon discount */
export const getPriceSaved = (
	breakup: any, // @ts-expect-error TS(2531): Object is possibly 'null'.
) => getInventoryDiscount(breakup) + getDiscount(breakup);

export const getTotalPriceOfSeats = (seats: any, currency: any) => {
	if (Array.isArray(seats) && seats.length) {
		let totalPrice = 0;
		for (let i = 0; i < seats.length; i++) {
			totalPrice += Number(seats[i].price);
		}
		return `${currency}${totalPrice.toFixed(2)}`;
	}
	return '';
};

export const getHeadoutDiscountPercentage = (booking: any) => {
	if (!booking) return 0;

	const breakup = getBreakupObject(booking);
	const pricePayable = getPricePayable(breakup);
	const inventoryDiscount = getInventoryDiscount(breakup);

	return inventoryDiscount
		? parseInt(
				// @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
				(inventoryDiscount / (pricePayable + inventoryDiscount)) * 100,
		  )
		: 0;
};

export const hasUnitPricePerPaxChanged = (breakup: any, prevBreakup: any) => {
	if (!prevBreakup || !breakup) return false;

	const [{ priceBreakupByPaxType }] = getBookingPriceBreakupList(breakup) ?? [
		{},
	];
	const [{ priceBreakupByPaxType: prevPriceBreakupByPaxType }] =
		getBookingPriceBreakupList(prevBreakup) ?? [{}];

	// From all selected pax from previous breakup,
	// check if per unit price of the pax, is indifferent.
	return Object.entries(prevPriceBreakupByPaxType || {}).some(
		([paxType, paxDetails]: any) => {
			// if new pax breakup doesnt have pax from previous run (i.e Pax Count was set to 0)
			// return price unchanged status for it
			if (!priceBreakupByPaxType[paxType]) return false;

			return (
				priceBreakupByPaxType[paxType].listingPrice /
					priceBreakupByPaxType[paxType].count !==
				paxDetails.listingPrice / paxDetails.count
			);
		},
	);
};

export const getHeadoutMembershipDiscount = (breakup: any) => {
	if (!breakup) return null;
	const pricePayableBreakup = getPricePayableBreakup(breakup);
	const membershipDiscountObj = pricePayableBreakup
		? pricePayableBreakup?.find(
				(item: any) =>
					item?.type === pricePaybleDiscountTypes.MEMBERSHIP_DISCOUNT,
		  )
		: null;
	return membershipDiscountObj ? membershipDiscountObj?.value * -1 : null;
};

export const validateComboPaxRatio = (
	booking: any,
):
	| false
	| { delta: number; tourId: string; minPax: number; maxPax: number } => {
	if (!booking.comboSelections) return false;
	const totalPaxForDifferentProducts: number[] = Object.values(
		booking.comboSelections,
	).map(
		({ tourSelectionMap = {} }: any) =>
			Object.values(tourSelectionMap).reduce(
				(accumulator: any, currentValue: any) =>
					accumulator + currentValue,
				0,
			) as number,
	);
	const minPax = Math.min(...totalPaxForDifferentProducts);
	const maxPax = Math.max(...totalPaxForDifferentProducts);

	if (minPax === 0) return false;

	if (maxPax > minPax * 2) {
		const tourId = Object.keys(booking.comboSelections)[
			totalPaxForDifferentProducts.indexOf(minPax)
		];
		return {
			delta: Math.ceil(maxPax / 2 - minPax),
			tourId,
			minPax,
			maxPax,
		};
	}

	return false;
};

export const getPricesBreakupBySeatId = (breakup: any) => {
	if (
		breakup?.priceBreakup?.variantBookingPriceBreakupList &&
		breakup?.priceBreakup?.variantBookingPriceBreakupList[0]
			?.bookingPriceBreakupList
	) {
		return breakup?.priceBreakup?.variantBookingPriceBreakupList[0]
			?.bookingPriceBreakupList[0]?.priceBreakupBySeatId;
	}

	return null;
};

export const getFeeLabel = (feeType: string) => {
	const feeString = feeType.toLowerCase();
	switch (feeString) {
		case FEE_LABELS.CANCELLATION_INSURANCE_FEE:
			return strings.FEE_TYPES.CANCELLATION_INSURANCE_FEE;
		case FEE_LABELS.CONVENIENCE_FEE:
			return strings.FEE_TYPES.CONVENIENCE_FEE;
		case FEE_LABELS.HELIPORT_FEE:
			return strings.FEE_TYPES.HELIPORT_FEE;
		case FEE_LABELS.PRIORITY_BOARDING:
			return strings.FEE_TYPES.PRIORITY_BOARDING;
		case FEE_LABELS.SERVICE_CHARGE:
			return strings.FEE_TYPES.SERVICE_CHARGE;
		case FEE_LABELS.SERVICE_TAX:
			return strings.FEE_TYPES.SERVICE_TAX;
		case FEE_LABELS.TAX:
			return strings.FEE_TYPES.TAX;
		case FEE_LABELS.VAT:
			return strings.FEE_TYPES.VAT;
		case FEE_LABELS.VENDOR_SERVICE_FEE:
			return strings.FEE_TYPES.VENDOR_SERVICE_FEE;
		case FEE_LABELS.INTREPID_MEUSEUM:
			return strings.FEE_TYPES.INTREPID_MEUSEUM;
		case FEE_LABELS.SEAT_RESERVATION_FEE:
			return strings.FEE_TYPES.SEAT_RESERVATION_FEE;
		default:
			return strings.FEE_TYPES.FEE;
	}
};
