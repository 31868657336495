import { ActionTypes } from 'Actions/actions';

const initialState = {
	byId: {},
	isFetching: {},
};

interface TTourIdMetadataTypes {
	tourId: number;
	ticketValidity: Object;
	cancellationPolicy: Object;
	paxSelectionInstructions: Object;
}

const getMetadataByTourId = (checkoutMetadata: Array<TTourIdMetadataTypes>) => {
	const metadataByTourIdMap = {};
	checkoutMetadata.forEach(el => {
		const { tourId, ...rest } = el;
		// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
		metadataByTourIdMap[tourId] = { ...rest };
	});
	return metadataByTourIdMap;
};

// @ts-expect-error TS(7006): Parameter 'action' implicitly has an 'any' type.
export const checkoutMetadataStore = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case ActionTypes.REQUEST_CHECKOUT_METADATA: {
			const { id } = payload;
			return {
				...state,
				isFetching: { ...state.isFetching, [id]: true },
			};
		}
		case ActionTypes.RECEIVE_CHECKOUT_METADATA: {
			const { id, checkoutMetadata } = payload;
			const metaDataByTourId = getMetadataByTourId(checkoutMetadata);
			return {
				...state,
				byId: { ...state.byId, [id]: metaDataByTourId },
				isFetching: { ...state.isFetching, [id]: false },
			};
		}
		default:
			return state;
	}
};
