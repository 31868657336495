import type { customUserFieldType } from 'Models/customField';

import { ActionTypes } from 'Actions/actions';

export const pricingActions = {
	REQUEST_PRICING: 'REQUEST_PRICING',
	RECEIVE_PRICING: 'RECEIVE_PRICING',
	REQUEST_PRICING_FOR_RANGE: 'REQUEST_PRICING_FOR_RANGE',
	RECEIVE_PRICING_FOR_RANGE: 'RECEIVE_PRICING_FOR_RANGE',
	FORMAT_PRICING: 'FORMAT_PRICING',
};

export const requestPricingForRange = ({
	productId,
	fromDate,
	toDate,
}: any) => ({
	payload: {
		productId,
		fromDate,
		toDate,
	},
	type: pricingActions.REQUEST_PRICING_FOR_RANGE,
});

export const receivePricingForRange = ({
	productId,
	variantId,
	pricingJSON,
	inventoryTimeZone,
	variants,
	currenciesMap,
	flushExistingInventories,
}: any) => ({
	type: pricingActions.RECEIVE_PRICING_FOR_RANGE,
	payload: {
		productId,
		variantId,
		pricingJSON,
		inventoryTimeZone,
		variants,
		currenciesMap,
		flushExistingInventories,
	},
});

export const recieveUserFields = ({
	productId,
	userFields,
}: {
	productId: string;
	userFields: customUserFieldType[];
}) => ({
	type: ActionTypes.RECIEVE_USER_FIELDS,
	payload: {
		productId,
		userFields,
	},
});
