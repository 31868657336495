import {
	getEncodedTTDPromoDetails,
	getUserCountryCode,
} from 'Utils/stateUtils';

import {
	ACTIVE_LANGUAGE_CODES,
	CUSTOM_HEADER,
	HEADER,
} from 'Constants/constants';

import { hasValidCurrencyCode, hasValidLanguageCode } from './bookingFlowUtils';
import { isServer } from './envUtils';
import { getCurrencyFromUrl, getLanguageFromUrl } from './urlUtils';

export const getPostRequest = (postData = {}, headers = {}) => ({
	method: 'POST',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		...headers,
	},
	body: JSON.stringify(postData),
});

export const getPutRequest = (putData = {}, headers = {}) => ({
	method: 'PUT',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		...headers,
	},
	body: JSON.stringify(putData),
});

export const getPatchRequest = (patchData = {}) => ({
	method: 'PATCH',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	body: JSON.stringify(patchData),
});

export const getDeleteRequest = () => ({
	method: 'DELETE',
	body: null,
});

export const getApiLanguageParameter = (currentLanguageCode: string) => {
	const languageFromUrl = !isServer() && getLanguageFromUrl(window.location);
	const languageCode = hasValidLanguageCode(
		languageFromUrl,
		currentLanguageCode,
	)
		? languageFromUrl
		: currentLanguageCode;
	const validatedLanguageCode =
		ACTIVE_LANGUAGE_CODES.includes(languageCode) && languageCode !== 'ar'
			? languageCode
			: '';
	return validatedLanguageCode ? `&language=${validatedLanguageCode}` : '';
};

export const getApiCurrencyParameter = (
	state: any,
	currentCurrencyCode: string,
) => {
	const currencyFromUrl = !isServer() && getCurrencyFromUrl(window.location);
	const currencyCode = hasValidCurrencyCode({
		state,
		currency: currencyFromUrl,
		currentCurrencyCode,
	})
		? currencyFromUrl
		: currentCurrencyCode;
	return currencyCode ? `&currency=${currencyCode}` : '';
};

export const getBaseRequestOptions = (
	state: any,
	baseOptions: RequestInit = {},
) => {
	const headers = new Headers(baseOptions?.headers || {});

	const ttdPromoDetails = getEncodedTTDPromoDetails(state);
	if (ttdPromoDetails?.length) {
		const country = getUserCountryCode(state);
		headers.set(CUSTOM_HEADER.FORWARDED_COUNTRY_CODE, country);
		headers.set(CUSTOM_HEADER.PROMO_DETAILS, ttdPromoDetails);
	}

	return Object.assign({}, baseOptions, { headers });
};
