/**
 *
 * @param N (integer)
 * @param items (Immutable list of numbers)
 * @returns {{beginIndex: number, maxCount: number}}
 */
export const getMaxSubarrayIncreasingByN = (N: any, items: number[]) => {
	let beginIndex = 0;
	let maxCount = 1;
	let count = 1;

	items.slice(1).forEach((item, index) => {
		if (item - items?.[index] === N) {
			count++;
		} else {
			count = 1;
		}
		if (count > maxCount) {
			beginIndex = index - count + 2;
			maxCount = count;
		}
	});
	return {
		beginIndex,
		maxCount,
	};
};

export const arrayMedian = (arr: Array<any>) => {
	const half = Math.floor(arr.length / 2);
	arr.sort((a, b) => a - b);

	if (arr.length % 2) return arr[half];
	return (arr[half - 1] + arr[half]) / 2.0;
};

export const getRandomObjectsBetweenIndices = (
	startIndex: number,
	stopIndex: number,
	inputArray: any[],
	maxSlides: number = 3,
) => {
	if (
		startIndex > stopIndex ||
		startIndex < 0 ||
		stopIndex >= inputArray.length
	) {
		return [];
	}

	const objectsInRange = inputArray.slice(startIndex, stopIndex + 1);

	if (objectsInRange.length <= maxSlides) {
		return objectsInRange;
	}

	const randomIndices = new Set();
	while (randomIndices.size < maxSlides) {
		const randomIndex = Math.floor(Math.random() * objectsInRange.length);
		randomIndices.add(randomIndex);
	}

	const randomObjects = Array.from(randomIndices).map(
		(_: any, index: number) => objectsInRange[index],
	);

	return randomObjects;
};

export const getUniqueListBy = (arr: any, key: any) => {
	// @ts-expect-error
	return [...new Map(arr.map(item => [item[key], item])).values()];
};
