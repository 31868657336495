import * as React from 'react';

export const HelpIcon = ({ stroke, ...props }: any) => (
	<svg
		viewBox='0 0 16 16'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		aria-hidden='true'
		{...props}
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.99992 1.83337C4.59416 1.83337 1.83325 4.59428 1.83325 8.00004C1.83325 11.4058 4.59416 14.1667 7.99992 14.1667C11.4057 14.1667 14.1666 11.4058 14.1666 8.00004C14.1666 4.59428 11.4057 1.83337 7.99992 1.83337ZM0.833252 8.00004C0.833252 4.042 4.04188 0.833374 7.99992 0.833374C11.958 0.833374 15.1666 4.042 15.1666 8.00004C15.1666 11.9581 11.958 15.1667 7.99992 15.1667C4.04188 15.1667 0.833252 11.9581 0.833252 8.00004Z'
			fill={stroke || '#666666'}
			className='helpIcon-path-fill'
		/>
		<path
			d='M6 6.00294C6.15674 5.55739 6.4661 5.18168 6.8733 4.94237C7.2805 4.70305 7.75926 4.61557 8.22478 4.69542C8.69031 4.77527 9.11254 5.01729 9.41672 5.37863C9.72089 5.73996 9.88737 6.19729 9.88667 6.66961C9.88667 8.00294 7.88667 8.66961 7.88667 8.66961'
			stroke={stroke || '#666666'}
			strokeLinecap='round'
			strokeLinejoin='round'
			className='helpIcon-path-stroke'
		/>
		<ellipse
			cx='7.99992'
			cy='11.3333'
			rx='0.666667'
			ry='0.666667'
			fill={stroke || '#666666'}
		/>
	</svg>
);
