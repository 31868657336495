// @ts-expect-error TS(7016): Could not find a declaration file for module 'loda... Remove this comment to see the full error message
import groupBy from 'lodash.groupby';
import type { customUserFieldType } from 'Models/customField';

import { concatenateUniqueAvailabilities } from 'Utils/pricingUtils';

import { ActionTypes } from 'Actions/actions';
import { pricingActions } from 'Actions/pricing';

import { PRICE_RANGE_DELIMETER } from 'Constants/constants';

// Sorting the timeslots by time, assuming that the startTime values are all in a standard 'HH:MM:SS' format
const createInventoryMapByDateTour = (inventoryMapByDate: any) => {
	const newInventoryMapByDate = { ...inventoryMapByDate };
	for (const item in newInventoryMapByDate) {
		const unSortedInventoryByTourDate = groupBy(
			newInventoryMapByDate[item],
			'tourId',
		);
		for (const item in unSortedInventoryByTourDate) {
			unSortedInventoryByTourDate[item] = unSortedInventoryByTourDate[
				item
			].sort(
				(a: any, b: any) =>
					parseFloat(String(a?.startTime).replace(':', '')) -
					parseFloat(String(b?.startTime).replace(':', '')),
			);
		}
		newInventoryMapByDate[item] = unSortedInventoryByTourDate;
	}
	return newInventoryMapByDate;
};

const createInventoryMapByDateTime = (inventoryMapByDate: any) => {
	const newInventoryMapByDateTime = { ...inventoryMapByDate };
	for (const item in newInventoryMapByDateTime) {
		newInventoryMapByDateTime[item] = groupBy(
			newInventoryMapByDateTime[item],
			'startTime',
		);
	}
	return newInventoryMapByDateTime;
};

const createInventoryMapByTourDate = (inventoryMapByTour: any) => {
	const newInventoryMapByTour = { ...inventoryMapByTour };
	for (const item in newInventoryMapByTour) {
		const unSortedInventoryByTourDate = groupBy(
			newInventoryMapByTour[item],
			'startDate',
		);
		for (const item in unSortedInventoryByTourDate) {
			unSortedInventoryByTourDate[item] = unSortedInventoryByTourDate[
				item
			].sort(
				(a: any, b: any) =>
					parseFloat(String(a?.startTime).replace(':', '')) -
					parseFloat(String(b?.startTime).replace(':', '')),
			);
		}
		newInventoryMapByTour[item] = unSortedInventoryByTourDate;
	}
	return newInventoryMapByTour;
};

const createSortedInventoryDatesByTour = (inventoryMapByTourDate: any) => {
	const newSortedInventoryMapByTourDate = { ...inventoryMapByTourDate };
	for (const item in newSortedInventoryMapByTourDate) {
		newSortedInventoryMapByTourDate[item] = Object.keys(
			newSortedInventoryMapByTourDate[item],
		).sort();
	}
	return newSortedInventoryMapByTourDate;
};

const formatTourData = (tours: any) => groupBy(tours, 'id');

const createPricingFromJson = (
	pricingJson: any,
	variantId: any,
	variants: any,
	currency: any,
	currentAvailabilities = [],
	userFields: customUserFieldType[],
) => {
	if (!variants?.length) {
		return null;
	}

	const accumulatedAvailabilities: any = concatenateUniqueAvailabilities(
		currentAvailabilities,
		pricingJson.availabilities,
	);

	// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
	const variantIds = variants.map(({ id }) => id);
	// @ts-expect-error TS(7031): Binding element 'tours' implicitly has an 'any' ty... Remove this comment to see the full error message
	const hasComboVariants = variants.some(({ tours }) => tours.length > 1);
	let { tours } =
		// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
		variants.find(({ id }) => Number(id) === Number(variantId)) ||
		// @ts-expect-error TS(7031): Binding element 'listingPrice' implicitly has an 'any' type.
		variants.find(({ listingPrice }) => listingPrice) ||
		variants[0];
	if (!hasComboVariants) {
		// @ts-expect-error TS(7031): Binding element 'tours' implicitly has an 'any' ty... Remove this comment to see the full error message
		tours = variants.map(({ tours }) => tours[0]);
	}
	tours = tours.map((tour: any) => {
		//TODO: Removes userfield from tour level - This can be removed post v6-product API has been deprecated.
		const { userFields: _unformattedUserFields, ...otherTourProps } = tour;
		return {
			...otherTourProps,
		};
	});

	// @ts-expect-error TS(7031): Binding element 'id' implicitly has an 'any' type.
	const tourIds = tours.map(({ id }) => id);
	let inventoryMapList = accumulatedAvailabilities;
	if (hasComboVariants) {
		inventoryMapList = accumulatedAvailabilities.filter((inv: any) =>
			tourIds.includes(inv?.tourId),
		);
	}
	const inventoryMapByDate = groupBy(inventoryMapList, 'startDate');
	const inventoryMapByTour = groupBy(inventoryMapList, 'tourId');
	const inventoryMap = createInventoryMapByDateTour(inventoryMapByDate);
	const inventoryMapByDateTime =
		createInventoryMapByDateTime(inventoryMapByDate);
	const inventoryMapByTourDate =
		createInventoryMapByTourDate(inventoryMapByTour);
	const sortedInventoryDates = Object.keys(inventoryMap).sort();
	const sortedInventoryDatesByTour = createSortedInventoryDatesByTour(
		inventoryMapByTourDate,
	);
	const tourMap = formatTourData(tours);

	return {
		variants,
		variantIds,
		tours,
		tourMap,
		tourIds,
		inventoryMap,
		inventoryMapByDateTime,
		inventoryMapByTourDate,
		sortedInventoryDates,
		sortedInventoryDatesByTour,
		inventoryMapByDate,
		...pricingJson,
		currency,
		availabilities: accumulatedAvailabilities,
		userFields,
	};
};

export const pricingStore = (state: any = {}, action: any) => {
	switch (action.type) {
		case pricingActions.REQUEST_PRICING_FOR_RANGE: {
			const {
				payload: { productId, fromDate, toDate },
			} = action;

			return {
				...state,
				status: {
					...state?.['status'],
					isFetching: {
						...state?.['status']?.['isFetching'],
						[String(productId)]: true,
						[`${String(productId)}${PRICE_RANGE_DELIMETER}${
							fromDate ?? ''
						}${PRICE_RANGE_DELIMETER}${toDate ?? ''}`]: true,
					},
				},
			};
		}
		case pricingActions.RECEIVE_PRICING_FOR_RANGE: {
			const {
				payload: {
					productId,
					variantId,
					pricingJSON,
					variants,
					currenciesMap,
					flushExistingInventories = false,
				},
			} = action;
			const { fromDate, toDate } = pricingJSON;
			const currentAvailabilities = !flushExistingInventories
				? (state as any)?.byProductId?.[String(productId)]
						?.availabilities ?? []
				: [];
			const userFields =
				state?.byProductId?.[String(productId)]?.userFields;

			const pricing = createPricingFromJson(
				pricingJSON,
				variantId,
				variants,
				currenciesMap[pricingJSON?.currencyCode],
				currentAvailabilities,
				userFields,
			);

			return {
				...state,
				byProductId: {
					...(state as any)['byProductId'],
					[String(productId)]: pricing,
				},
				status: {
					...(state as any).status,
					isFetching: {
						...(!flushExistingInventories
							? (state as any)?.status?.isFetching
							: null),
						[String(productId)]: false,
						[`${String(productId)}${PRICE_RANGE_DELIMETER}${
							fromDate ?? ''
						}${PRICE_RANGE_DELIMETER}${toDate ?? ''}`]: false,
					},
				},
			};
		}

		case ActionTypes.RECIEVE_USER_FIELDS: {
			const {
				payload: { productId, userFields },
			} = action;

			const productPricingState =
				state?.byProductId?.[String(productId)] ?? {};

			return {
				...state,
				byProductId: {
					...state['byProductId'],
					[String(productId)]: {
						...productPricingState,
						userFields,
					},
				},
			};
		}

		default:
			return state;
	}
};
